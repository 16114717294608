import React, { FunctionComponent } from "react";
import "./App.css";
import {
  createMuiTheme,
  MuiThemeProvider,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { Route } from "react-router-dom";
import LandingSite from "./sites/LandingSite";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileButton: {
      position: "fixed",
      right: 10,
      bottom: 10,
      zIndex: 999,
    },
    mobileBadge: {
      background: theme.palette.secondary.main,
      color: "#fff",
      margin: 2,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    root: {
      padding: theme.spacing(4),
    },
    "@media (max-width: 812px)": {
      root: {
        padding: theme.spacing(2),
      },
    },
  })
);

const App: FunctionComponent = () => {
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 800,
        lg: 900,
        xl: 1200,
      },
    },
    palette: {
      primary: { main: "#FFF" },
      secondary: { main: "rgb(0, 141, 207)" },
    },
    typography: {
      fontFamily: '"Open Sans"',
    },
  });

  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <div id="app" className="App">
          <Route path="/" exact={true} component={LandingSite} />
        </div>
      </React.Fragment>
    </MuiThemeProvider>
  );
};

export default App;
