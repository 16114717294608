import React, { FunctionComponent, useState } from "react";
import logo from "../assets/ki-test-logo.png";
import banner from "../assets/banner.png";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import _Div100vh from "react-div-100vh";
import { Link, animateScroll as scroll } from "react-scroll";
import axios, { AxiosResponse } from "axios";

import {
  Typography,
  makeStyles,
  Box,
  Avatar,
  IconButton,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

// FIXME: a newer version of react-div-100vh with typescript types exists.
const Div100vh = _Div100vh as any;

function validateEmail(email: string) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFF"
  },
  homepageContainer: {
    background: "#c3c3c3"
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logo: {
    width: "250px",
    height: "auto",
    marginBottom: "10px"
  },
  bannerContainer: {
    width: "100%",
    display: "flex",
    gap: "5rem",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    background: "#148ECF",
    height: "100%",
    padding: "7rem",
    boxSizing: "border-box"
  },
  bannerWrapper: {
    justifyContent: "flex-end"
  },
  banner: {
    maxWidth: "400px"
  },
  textContainer: {
    textAlign: "left",
    color: theme.palette.primary.main
  },
  contentContainer: {
    textAlign: "center",
    color: "black",
    maxWidth: "800px"
  },
  formContainer: {
    textAlign: "left",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    maxWidth: "800px",
    "& svg": {
      color: theme.palette.primary.main
    }
  },
  footer: {
    height: "50px",
    lineHeight: "50px",
    color: "black",
    background: theme.palette.primary.main
  },
  submit: {
    marginTop: "1em",
    color: theme.palette.secondary.main
  },
  marginBottom: {
    marginTop: "0.5em"
  },
  vh: {
    width: "100%"
  },
  text: {
    height: "100%"
  },
  headline: {
    marginBottom: "2rem"
  },
  bannerAlign: {
    justifyContent: "flex-end"
  },
  helperText: {
    color: "white"
  },
  header: {
    background: "white",
    position: "fixed",
    width: "100%",
    zIndex: 100
  },
  disabledButton: {
    color: "white !important"
  },
  autohyphen: {
    hyphens: "auto"
  },
  req: {
    padding: "0.25rem",
    boxSizing: "border-box",
    "& input": {
      color: theme.palette.primary.main
    },
    "& label": {
      color: theme.palette.primary.main
    },
    "& fieldset": {
      borderColor: theme.palette.primary.main + " !important"
    }
  },
  opt: {
    padding: "0.25rem",
    boxSizing: "border-box",
    "& input": {
      color: theme.palette.primary.dark
    },
    "& label": {
      color: theme.palette.primary.dark
    },
    "& fieldset": {
      borderColor: theme.palette.primary.dark + " !important"
    }
  },
  label: {
    marginTop: "1rem",
    marginLeft: "-0.25rem"
  },
  link: {
    color: theme.palette.primary.main
  },
  linkDark: {
    color: theme.palette.secondary.main
  },
  circle: {
    counterReset: "list-counter",
    listStyle: "none",
    float: "left",
    "& li:before": {
      content: "counter(list-counter)",
      counterIncrement: "list-counter",
      width: "1.5em",
      marginRight: "1em",
      borderRadius: "50%",
      border: ".25em solid",
      textAlign: "center",
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      display: "inline-block"
    },
    "& li": {
      margin: "1.5em 0"
    },
    "& p": {
      paddingLeft: "2.5rem",
      marginTop: "-1.75rem"
    }
  },
  "@media (max-width: 768px)": {
    header: {
      height: "55px",
      "& img": {
        width: "150px"
      }
    },
    bannerContainer: {
      flexWrap: "wrap",
      padding: "4rem 2rem"
    },
    bannerWrapper: {
      width: "100%",
      alignItems: "flex-end",
      justifyContent: "center"
    },
    banner: {
      width: "250px"
    },
    // bannerAlign: {},
    text: {
      alignItems: "flex-start",
      justifyContent: "center"
    },
    submit: {
      width: "100%"
    },
    headline: {
      fontSize: "2rem",
      textAlign: "center"
    }
  }
}));

const startSurvey: (email: string, marketing: boolean, firstname: string, lastname: string) => Promise<string> = async (
  email: string,
  marketing: boolean,
  firstname: string,
  lastname: string
) => {
  const response: AxiosResponse<string> = await axios.request<string>({
    url: "/api/survey",
    method: "POST",
    data: { email, marketing, firstname, lastname }
  });
  return Promise.resolve(response.data);
};

const LandingSite: FunctionComponent = () => {
  const classes = useStyles();
  const [validMail, setValidMail] = useState(false);
  const [validCheck, setValidCheck] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    marketing: false,
    firstname: "",
    lastname: ""
  });

  const label = (
    <span>
      Ja, ich habe die Hinweise zum&nbsp;
      <a className={classes.link} href="https://www.ki-management.com/datenschutz-teilnehmer/" target="_blank">
        Datenschutz
      </a>
      &nbsp;gelesen und erkläre mich mit ihnen einverstanden.
    </span>
  );

  return (
    <Grid container className={classes.root} data-cy="homepageRoot">
      <Grid className={classes.header} xs={12}>
        <img alt="logo" className={classes.logo} src={logo} />
      </Grid>
      <Div100vh className={classes.vh} style={{ minHeight: "100rvh" }}>
        <Grid xs={12} container className={classes.bannerContainer}>
          <Grid container className={classes.bannerWrapper} xs={12} sm={6}>
            <img alt="banner" className={classes.banner} src={banner} />
          </Grid>
          <Grid className={classes.textContainer} alignItems="center" xs={12} sm={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.text}>
              <Typography variant="h3" className={`${classes.headline} ${classes.autohyphen}`} align="left">
                Mach den Persönlichkeits&shy;check
              </Typography>
              <Typography gutterBottom variant="body1" className={classes.autohyphen}>
                In 10 Minuten erhältst du ein fundiertes Feedback zu deiner berufsbezogenen Persönlichkeit.
              </Typography>
              <Link activeClass="active" to="register" spy={true} smooth={true} duration={500}>
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<ArrowDownwardIcon />}
                >
                  Zur kostenfreien Durchführung
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Div100vh>
      <Div100vh className={classes.vh} style={{ minHeight: "100rvh" }}>
        <Grid style={{ height: "100%", padding: "1em" }} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center" css={{ height: "100%" }}>
            <Box className={classes.contentContainer} flexDirection="column">
              <Typography gutterBottom variant="h3" className={`${classes.headline} ${classes.autohyphen}`}>
                Der Persönlichkeits&shy;check
              </Typography>
              <Typography
                style={{ marginBottom: "1rem" }}
                gutterBottom
                align="justify"
                variant="body1"
                className={classes.autohyphen}
              >
                Bei der Vielzahl an Ausbildungs- und Studiengängen ist es oft schwer, die richtige Entscheidung für die
                Zukunft zu treffen: Welcher Job passt zu mir? Welche Stärken bringe ich bereits mit und bei welchem Job
                kann ich meine Stärken gut einsetzen und weiter ausbauen? Auch im aktuellen Beruf kann es wertvoll zu
                sein zu wissen, welche persönlichkeitsbezogenen Stärken man bereits mitbringt, um diese gewinnnbringend
                im Job einsetzen zu können.
              </Typography>
              <Typography
                style={{ marginBottom: "2rem" }}
                gutterBottom
                align="justify"
                variant="body1"
                className={classes.autohyphen}
              >
                Um dir bei diesen Fragen zu helfen, stellt dir die KI.TEST GmbH einen kostenfreien Zugang zum
                Persönlichkeitscheck zur Verfügung. Der Persönlichkeitscheck ist wissenschaftlich konstruiert und
                erfasst zentrale Persönlichkeitsmerkmale, die im Beruf und der Ausbildung eine Rolle spielen (z.B.
                Teamorientierung, Belastbarkeit).
              </Typography>
              <Typography align="justify" variant="h5" className={classes.autohyphen}>
                Deine Benefits:
              </Typography>
              <Typography
                style={{ marginBottom: "2rem" }}
                gutterBottom
                align="left"
                variant="body1"
                className={classes.autohyphen}
              >
                <ul className={classes.circle}>
                  <li>
                    <p className={classes.autohyphen}>
                      Bereits die Bearbeitung des Persönlichkeitschecks fördert deine Selbstreflexion (u.a. Was sind
                      meine typischen Verhaltensweisen?)!
                    </p>
                  </li>
                  <li>
                    <p className={classes.autohyphen}>
                      Als Feedback erhälst du einen individuellen Ergebnisreport (als PDF-Datei), der deine
                      berufsbezogene Persönlichkeit beschreibt.
                    </p>
                  </li>
                  <li>
                    <p className={classes.autohyphen}>
                      Der Ergebnisreport wird dir dabei helfen zu erkennen, welche individuellen Stärken und
                      Verbesserungsbereiche in deiner Persönlichkeit bereits angelegt sind.
                    </p>
                  </li>
                </ul>
                Wie ist der Prozess? Ganz einfach! Du meldest dich unten mit deiner E-Mailadresse an und kannst direkt
                loslegen.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Div100vh>
      <Div100vh className={classes.vh} id="register" style={{ minHeight: "100rvh" }}>
        <Grid style={{ height: "100%", padding: "1em", background: "#148ECF" }} xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            css={{ height: "100%", margin: "auto" }}
          >
            <Box className={classes.formContainer} flexDirection="column" css={{ margin: "auto" }}>
              <Typography
                gutterBottom
                align="center"
                variant="h3"
                className={`${classes.headline} ${classes.autohyphen}`}
              >
                Starte deinen Persönlichkeits&shy;check
              </Typography>
              <Grid container>
                <Grid xs={12} className={classes.req}>
                  <TextField
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    helperText="An diese Adresse senden wir dir dein Ergebnis zu."
                    className={classes.marginBottom}
                    fullWidth
                    onChange={(e) => {
                      setValidMail(validateEmail(e.target.value));
                      setFormData({ ...formData, email: e.target.value });
                    }}
                    value={formData.email}
                    variant="outlined"
                    required
                    label="E-Mailadresse"
                  />
                </Grid>
                <Grid xs={12} sm={6} className={classes.opt}>
                  <TextField
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    className={classes.marginBottom}
                    fullWidth
                    onChange={(e) => {
                      setFormData({ ...formData, firstname: e.target.value });
                    }}
                    value={formData.firstname}
                    variant="outlined"
                    label="Vorname (optional)"
                  />
                </Grid>
                <Grid xs={12} sm={6} className={classes.opt}>
                  <TextField
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                    className={classes.marginBottom}
                    fullWidth
                    onChange={(e) => {
                      setFormData({ ...formData, lastname: e.target.value });
                    }}
                    value={formData.lastname}
                    variant="outlined"
                    label="Nachname (optional)"
                  />
                </Grid>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setValidCheck(e.target.checked);
                      }}
                      value={validCheck}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={label}
                />
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          marketing: e.target.checked
                        });
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Ja, ich würde zu einem späteren Zeitpunkt gerne weiterführende Informationen zum Persönlichkeitscheck per Mail erhalten. Mein Einverständnis kann ich jederzeit widerrufen."
                />
                <Box css={{ width: "100%", textAlign: "left" }}>
                  <Button
                    className={classes.submit}
                    disabled={!validMail || !validCheck}
                    classes={{ disabled: classes.disabledButton }}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={async () => {
                      const usertoken: string = await startSurvey(
                        formData.email,
                        formData.marketing,
                        formData.firstname,
                        formData.lastname
                      );
                      window.location.href = `https://kim.timetoact-group.de/limesurvey/index.php/656533?token=${usertoken}&newtest=Y`;
                    }}
                  >
                    {((!validMail || !validCheck) && "Fülle alle Pflichtfelder aus") ||
                      "Starte deinen Persönlichkeitscheck"}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Box style={{ height: "1em" }}>
              <a className={classes.link} href="https://ki-test.com/impressum/" target="_blank">
                Impressum
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid className={classes.footer} xs={12}>
          Besucht gerne auch unsere Homepage:{" "}
          <a className={classes.linkDark} href="https://www.ki-test.com/" target="_blank">
            www.ki-test.com
          </a>
        </Grid>
      </Div100vh>
    </Grid>
  );
};
export default LandingSite;
